import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Events } from '@ionic/angular';
import { first, map } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { SharedService } from '../shared/shared.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class RatingService {
  allRatings = [];
  lastRating: any;
  constructor(private events: Events,
              private afs: AngularFirestore,
              private logglyService: LogglyLoggerService,
              private sharedService: SharedService,
              private utilsService:UtilsService,
              private fbStorage: AngularFireStorage,
              private configService: ConfigService) { }

  initializeSubscriptions() {
    this.events.subscribe('rating:submitRating', (data, productId, productIndex, orderId) => {
      this.submitRating(data, productId, productIndex, orderId);
    });
    this.events.subscribe('rating:submitOrderRating', (data, orderId) => {
      this.submitOrderRating(data, orderId);
    });
    this.events.subscribe('rating:checkProductBought', (productId, action) => {
      this.checkProductBought(productId, action);
    });
    this.events.subscribe('rating:getLimitedProductRatings', (productId) => {
      this.getLimitedProductRatings(productId);
    });
    this.events.subscribe('rating:getRatings', (productId) => {
      this.getRatings(productId);
    });
    this.events.subscribe('rating:getMoreRatings', (productId) => {
      this.getMoreRatings(productId);
    });
  }

  async submitRating(ratingData: any, productId: string, productIndex: number, orderId: string) {
    try {
      const uid = await this.sharedService.getStorageUid();
      const ratingRef = this.afs.collection('products').doc(productId).collection('ratings').doc(uid);
      let data: any = {};
      if(ratingData.photos.length > 0) {
        data = JSON.parse(JSON.stringify(ratingData));
        delete ratingData.photos;
      }
      ratingData['createdAt'] = new Date();
      await ratingRef.set(ratingData);

      if(data && data.photos && data.photos.length) {
        for (const img of data.photos) {
          const imgRef: any = this.fbStorage.ref(`productRatings/${productId}/${uid}/images/` + new Date().getTime().toString() + '.png');
          await imgRef.putString(img.url, 'data_url');
        }
      }

      if(productIndex && orderId && productIndex !== -1) {
        const order: any = await this.afs.collection('orders').doc(orderId).valueChanges().pipe(first()).toPromise();
        order.products[productIndex]['rating'] = ratingData.rating;
        await this.afs.collection('orders').doc(orderId).update({
          products: order.products
        });
      }

      this.events.publish('rating:submitRatingSuccess');
      
    } catch (error) {
      console.dir(error);
      error['location'] = 'rating-service:submitRating'; 
      this.logglyService.log(error);
    }
  }


  async submitOrderRating(ratingData: any, orderId: string) {
   this.utilsService.consoleLog('order rating service order id', orderId)
    try {
      const orderRef = this.afs.collection('orders').doc(orderId);
      let data: any = {};
      data = JSON.parse(JSON.stringify(ratingData));
      if(ratingData.photos.length > 0) {
        delete data.photos;
      }
      data['createdAt'] = new Date();
      await orderRef.update({rating: data});

      if(ratingData.photos.length > 0) {
        for (const img of ratingData.photos) {
          const imgRef: any = this.fbStorage.ref(`orders/${orderId}/rating/images/` + new Date().getTime().toString() + '.png');
          await imgRef.putString(img.url, 'data_url');
        }
      }

      this.events.publish('rating:submitOrderRatingSuccess');
      
    } catch (error) {
      console.dir(error);
      error['location'] = 'rating-service:submitRating'; 
      this.logglyService.log(error);
    }
  }

  


  async checkProductBought(pid: string, action: string) {
    try {
      let isProductBought = false;
      const uid = await this.sharedService.getStorageUid();
      if(action === 'order-product') {
        isProductBought = true;
      } else {
        const ordersRef = this.afs.collection('orders', ref => ref
        .where('userId', '==', uid)
        .where('status', '==', 'Delivered'));
        const orders: any = await ordersRef.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          }))
        ).pipe(first()).toPromise();
        isProductBought = false;
        for (const order of orders) {
          if(order && order.products) {
            for (const pdt of order.products) {
              if(pdt.productId === pid) {
                isProductBought = true;
                break;
              }
            }
            if(isProductBought) {
              break;
            }
          }
        }
      }
      if(isProductBought) {
        const userRating = await this.afs.collection('products').doc(pid).collection('ratings').doc(uid).valueChanges().pipe(first()).toPromise();
        if(!userRating) {
          this.events.publish('rating:productChecked', true);
        } else {
          this.events.publish('rating:productReviewed');
        }
      } else {
        this.events.publish('rating:productChecked', false);
      }
      
    } catch (error) {
      console.dir(error);
      error['location'] = 'rating-service:checkProductBought'; 
      this.logglyService.log(error);
    }
  }


  async getLimitedProductRatings(pid: string) {
    try {
      let ratingsDocData = null;
      const ratings = await this.afs.collection('products').doc(pid).collection('ratings', ref => ref
      .where('status', '==', 'approved')
      .orderBy('createdAt', 'desc')
      .limit(3))
      .valueChanges().pipe(first()).toPromise();

      if(ratings.length) {
        const ratingsDoc: any = await this.afs.collection('products').doc(pid).valueChanges().pipe(first()).toPromise();
        ratingsDocData = ratingsDoc.rating;
      }

      this.events.publish('rating:limitedProductRatings', ratings, ratingsDocData);
      
    } catch (error) {
      console.dir(error);
      error['location'] = 'rating-service:getLimitedProductRatings'; 
      this.logglyService.log(error);
    }
  }

  async getRatings(pid: string) {
    try {
      this.allRatings = [];
      const ratings = await this.afs.collection('products').doc(pid).collection('ratings', ref => ref
      .where('status', '==', 'approved')
      .orderBy('createdAt', 'desc')
      .limit(this.configService.environment.scrollLimit))
      .snapshotChanges().pipe(first()).toPromise();

      if(ratings.length) {
        this.lastRating = ratings[ratings.length - 1].payload.doc;
        for (const rat of ratings) {
          this.allRatings.push({id: rat.payload.doc.id, ...rat.payload.doc.data()});
        }
      }

      this.events.publish('rating:productRatings', this.allRatings);
      
    } catch (error) {
      console.dir(error);
      error['location'] = 'rating-service:getRatings'; 
      this.logglyService.log(error);
    }
  }

  async getMoreRatings(pid: string) {
    try {
      const ratings: any = await this.afs.collection('products').doc(pid).collection('ratings', ref => ref
      .where('status', '==', 'approved')
      .orderBy('createdAt', 'desc')
      .limit(this.configService.environment.scrollLimit)
      .startAfter(this.lastRating)).snapshotChanges().pipe(first()).toPromise();

      if (ratings.length > 0) {
        this.lastRating = ratings[ratings.length - 1].payload.doc;
        for (const rat of ratings) {
          this.allRatings.push({id: rat.payload.doc.id, ...rat.payload.doc.data()});
        }
      } else {
        this.events.publish('rating:noMoreRatings');
      }
      
      this.events.publish('rating:productRatings', this.allRatings);
    } catch (error) {
      console.dir(error);
      error['location'] = 'rating-service:getMoreRatings'; 
      this.logglyService.log(error);
    }
}
}
