import { Component, OnInit } from '@angular/core';
import { AlertController, Events, LoadingController, ModalController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';


@Component({
  selector: 'app-rate-product',
  templateUrl: './rate-product.page.html',
  styleUrls: ['./rate-product.page.scss'],
})
export class RateProductPage implements OnInit {
  productId: string;
  product: any;
  reviewPh = '';
  SHARED_LABELS: any;
  RATE_PRODUCT_LABELS: any;
  ratingData = {
    status: 'pending',
    rating: 5,
    review: '',
    photos: [],
    userName: ''
  }
  loading: any;
  rating: number = 5;
  productIndex: number = -1;
  orderId: string = '';
  parentProductId: string;
  constructor(private modalController: ModalController,
              private events: Events,
              private labelService: LabelService,
              private loadingController: LoadingController,
              private alertController: AlertController) { }

  ngOnInit() {
    // console.log(this.productId);
    // console.log(this.product);
    this.SHARED_LABELS = this.labelService.labels['SHARED'];
    this.RATE_PRODUCT_LABELS = this.labelService.labels['RATE_PRODUCT'];
    this.reviewPh = this.RATE_PRODUCT_LABELS['review_placeholder'];
  }

  async ionViewWillEnter() {
    this.initializeSubscriptions();
    await this.presentLoading();
    this.events.publish('rating:checkProductBought', this.productId, this.orderId ? 'order-product' : '');
  }

  ionViewWillLeave() {
    this.removeSubscriptions();
  }

  initializeSubscriptions() {
    this.events.subscribe('rating:submitRatingSuccess', () => {
      if(this.loading){ this.loading.dismiss() };
      this.presentAlert(this.RATE_PRODUCT_LABELS['thanks_for_rating'], true);
    });
    this.events.subscribe('rating:productChecked', (isProductBought) => {
      if(this.loading){ this.loading.dismiss() };
      if(!isProductBought) {
        this.presentAlert(this.RATE_PRODUCT_LABELS['product_cant_review'], true);
      }
    });
    this.events.subscribe('rating:productReviewed', () => {
      if(this.loading){ this.loading.dismiss() };
      this.presentAlert(this.RATE_PRODUCT_LABELS['product_already_reviewed'], true);
    });
  }

  removeSubscriptions() {
    this.events.unsubscribe('rating:submitRatingSuccess');
    this.events.unsubscribe('rating:productChecked');
    this.events.unsubscribe('rating:productReviewed');
  }

  closeModal(action?: string) {
    this.modalController.dismiss(action);
  }


  async submit() {
    if(!this.ratingData.userName) {
      this.presentAlert(this.RATE_PRODUCT_LABELS['enter_public_name'], false);
    } else {
      await this.presentLoading();
      if (this.parentProductId && this.orderId) {
        this.events.publish('rating:submitRating', this.ratingData, this.parentProductId, this.productIndex, this.orderId);
      }
      this.events.publish('rating:submitRating', this.ratingData, this.productId);
      
      // this.events.publish('rating:submitRating', this.ratingData, this.parentProductId, this.productIndex, this.orderId);
      // this.events.publish('rating:submitRating', this.ratingData, this.productId);
    }
    
  }

  async rate(data: any) {
    this.ratingData.rating = data.rating;
    this.ratingData.review = data.review;
    this.ratingData.photos = data.photos;
    this.ratingData.userName = data.userName;

    await this.submit();
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      message: this.SHARED_LABELS['please_wait'],
      duration: 10000,
    });
    await this.loading.present();
  }

  async presentAlert(msg: string, action: boolean) {
    const alert = await this.alertController.create({
      message: msg,
      backdropDismiss: false,
      buttons: [{
        text: this.SHARED_LABELS['ok'],
        handler: () => {
          if(action) {
            this.closeModal(this.orderId ? 'order-product': '');
          }
        }
      }]
    });
  
    await alert.present();
  }



}