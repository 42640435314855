import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Events } from '@ionic/angular';
import { first, map } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { SharedService } from '../shared/shared.service';
import { UtilsService } from '../utils/utils.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "../loggly-logger/loggly-logger.service";
import * as i4 from "../shared/shared.service";
import * as i5 from "../utils/utils.service";
import * as i6 from "@angular/fire/storage";
import * as i7 from "../config/config.service";
export class RatingService {
    constructor(events, afs, logglyService, sharedService, utilsService, fbStorage, configService) {
        this.events = events;
        this.afs = afs;
        this.logglyService = logglyService;
        this.sharedService = sharedService;
        this.utilsService = utilsService;
        this.fbStorage = fbStorage;
        this.configService = configService;
        this.allRatings = [];
    }
    initializeSubscriptions() {
        this.events.subscribe('rating:submitRating', (data, productId, productIndex, orderId) => {
            this.submitRating(data, productId, productIndex, orderId);
        });
        this.events.subscribe('rating:submitOrderRating', (data, orderId) => {
            this.submitOrderRating(data, orderId);
        });
        this.events.subscribe('rating:checkProductBought', (productId, action) => {
            this.checkProductBought(productId, action);
        });
        this.events.subscribe('rating:getLimitedProductRatings', (productId) => {
            this.getLimitedProductRatings(productId);
        });
        this.events.subscribe('rating:getRatings', (productId) => {
            this.getRatings(productId);
        });
        this.events.subscribe('rating:getMoreRatings', (productId) => {
            this.getMoreRatings(productId);
        });
    }
    submitRating(ratingData, productId, productIndex, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const uid = yield this.sharedService.getStorageUid();
                const ratingRef = this.afs.collection('products').doc(productId).collection('ratings').doc(uid);
                let data = {};
                if (ratingData.photos.length > 0) {
                    data = JSON.parse(JSON.stringify(ratingData));
                    delete ratingData.photos;
                }
                ratingData['createdAt'] = new Date();
                yield ratingRef.set(ratingData);
                if (data && data.photos && data.photos.length) {
                    for (const img of data.photos) {
                        const imgRef = this.fbStorage.ref(`productRatings/${productId}/${uid}/images/` + new Date().getTime().toString() + '.png');
                        yield imgRef.putString(img.url, 'data_url');
                    }
                }
                if (productIndex && orderId && productIndex !== -1) {
                    const order = yield this.afs.collection('orders').doc(orderId).valueChanges().pipe(first()).toPromise();
                    order.products[productIndex]['rating'] = ratingData.rating;
                    yield this.afs.collection('orders').doc(orderId).update({
                        products: order.products
                    });
                }
                this.events.publish('rating:submitRatingSuccess');
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'rating-service:submitRating';
                this.logglyService.log(error);
            }
        });
    }
    submitOrderRating(ratingData, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.utilsService.consoleLog('order rating service order id', orderId);
            try {
                const orderRef = this.afs.collection('orders').doc(orderId);
                let data = {};
                data = JSON.parse(JSON.stringify(ratingData));
                if (ratingData.photos.length > 0) {
                    delete data.photos;
                }
                data['createdAt'] = new Date();
                yield orderRef.update({ rating: data });
                if (ratingData.photos.length > 0) {
                    for (const img of ratingData.photos) {
                        const imgRef = this.fbStorage.ref(`orders/${orderId}/rating/images/` + new Date().getTime().toString() + '.png');
                        yield imgRef.putString(img.url, 'data_url');
                    }
                }
                this.events.publish('rating:submitOrderRatingSuccess');
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'rating-service:submitRating';
                this.logglyService.log(error);
            }
        });
    }
    checkProductBought(pid, action) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let isProductBought = false;
                const uid = yield this.sharedService.getStorageUid();
                if (action === 'order-product') {
                    isProductBought = true;
                }
                else {
                    const ordersRef = this.afs.collection('orders', ref => ref
                        .where('userId', '==', uid)
                        .where('status', '==', 'Delivered'));
                    const orders = yield ordersRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return Object.assign({ id }, data);
                    }))).pipe(first()).toPromise();
                    isProductBought = false;
                    for (const order of orders) {
                        if (order && order.products) {
                            for (const pdt of order.products) {
                                if (pdt.productId === pid) {
                                    isProductBought = true;
                                    break;
                                }
                            }
                            if (isProductBought) {
                                break;
                            }
                        }
                    }
                }
                if (isProductBought) {
                    const userRating = yield this.afs.collection('products').doc(pid).collection('ratings').doc(uid).valueChanges().pipe(first()).toPromise();
                    if (!userRating) {
                        this.events.publish('rating:productChecked', true);
                    }
                    else {
                        this.events.publish('rating:productReviewed');
                    }
                }
                else {
                    this.events.publish('rating:productChecked', false);
                }
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'rating-service:checkProductBought';
                this.logglyService.log(error);
            }
        });
    }
    getLimitedProductRatings(pid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let ratingsDocData = null;
                const ratings = yield this.afs.collection('products').doc(pid).collection('ratings', ref => ref
                    .where('status', '==', 'approved')
                    .orderBy('createdAt', 'desc')
                    .limit(3))
                    .valueChanges().pipe(first()).toPromise();
                if (ratings.length) {
                    const ratingsDoc = yield this.afs.collection('products').doc(pid).valueChanges().pipe(first()).toPromise();
                    ratingsDocData = ratingsDoc.rating;
                }
                this.events.publish('rating:limitedProductRatings', ratings, ratingsDocData);
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'rating-service:getLimitedProductRatings';
                this.logglyService.log(error);
            }
        });
    }
    getRatings(pid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.allRatings = [];
                const ratings = yield this.afs.collection('products').doc(pid).collection('ratings', ref => ref
                    .where('status', '==', 'approved')
                    .orderBy('createdAt', 'desc')
                    .limit(this.configService.environment.scrollLimit))
                    .snapshotChanges().pipe(first()).toPromise();
                if (ratings.length) {
                    this.lastRating = ratings[ratings.length - 1].payload.doc;
                    for (const rat of ratings) {
                        this.allRatings.push(Object.assign({ id: rat.payload.doc.id }, rat.payload.doc.data()));
                    }
                }
                this.events.publish('rating:productRatings', this.allRatings);
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'rating-service:getRatings';
                this.logglyService.log(error);
            }
        });
    }
    getMoreRatings(pid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const ratings = yield this.afs.collection('products').doc(pid).collection('ratings', ref => ref
                    .where('status', '==', 'approved')
                    .orderBy('createdAt', 'desc')
                    .limit(this.configService.environment.scrollLimit)
                    .startAfter(this.lastRating)).snapshotChanges().pipe(first()).toPromise();
                if (ratings.length > 0) {
                    this.lastRating = ratings[ratings.length - 1].payload.doc;
                    for (const rat of ratings) {
                        this.allRatings.push(Object.assign({ id: rat.payload.doc.id }, rat.payload.doc.data()));
                    }
                }
                else {
                    this.events.publish('rating:noMoreRatings');
                }
                this.events.publish('rating:productRatings', this.allRatings);
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'rating-service:getMoreRatings';
                this.logglyService.log(error);
            }
        });
    }
}
RatingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RatingService_Factory() { return new RatingService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.LogglyLoggerService), i0.ɵɵinject(i4.SharedService), i0.ɵɵinject(i5.UtilsService), i0.ɵɵinject(i6.AngularFireStorage), i0.ɵɵinject(i7.ConfigService)); }, token: RatingService, providedIn: "root" });
